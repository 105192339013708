import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => { 
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Man</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Man</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <h1 className="playfair text-black text-xl md:text-3xl">About Aries </h1>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
              Aries Man Traits:
              </p>Strong, bold, impulsive, self-centered, ambitious, short-tempered, wild, fiery, enthusiastic, excited, energetic
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">
 Compatible Signs for Aries Man:
              </p>Gemini, Leo, Sagittarius, and Aquarius 
 <br/><br/>
Aries is the first sign of the zodiac. Hence, Aries men love to be number one and are full of attention seekers. The planet Mars rules you; work in your manner and on your terms and conditions. You are stubborn by nature and stick to accomplishing things in a way you have thought of. Hence, it is tough and challenging for your coworkers and subordinates to keep up with you. You need to tolerate suggestions and learn to accept irregularities and points of view of others. Doing so would be very straightforward for you to gel with encircling people. You are a combination of a courageous warrior and an undisciplined child. Are you seeking to learn your natural qualities and core strengths better? Get your free personalized Janampatri! 
 <br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
 Let's take a look at Aries men's traits
                </p>
 
 <br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Strong & bold
                </p>
 <br/>
You are always strong, fiery, bold, and confident. These qualities of yours make you different ahead of others. During uncertainties and tense situations, your stability, maturity, and morale would be at the same pace. You are not scared to explore the unknown. You are a daring and secure creature and willing to take the lead. Your naturally competitive nature would push you to head on for challenges and barriers, arriving on the path of victory and achieving a milestone. <br/>
 <br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Impulsive
                </p><br/>
Aries men are impulsive and stubborn with opinions and thoughts. You are intuitive and self-dependent. You don't tolerate recommendations and get extremely upset if somebody argues with you regularly. You won't answer anyone and perform with your pace and zeal. You are impatient with stragglers.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Self-centered
                </p><br/>
At first sight, individuals judge you as an ungenerous and self-centered person. You become selfish and selective when your goals and desires get delayed. You are selfless and a faithful best buddy as you are ready to support others without any anticipation of gaining credit in return. You are entirely involved in your goal achievement and perfection at work. But it would come at the cost of a personal connection. You can break the heart of your valued ones when you are under too much work tension and surrounded by challenges in professional life. Now it's time to overcome all the challenges in my personal and professional life. Get your free personalized 2020 yearly report. 
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
 Competitive and Ambitious
                </p><br/>
You have the core stability and strength to win over the universe. You are ambitious to be on top in your respective field. You are incredibly competitive and hardworking in all dimensions of life. You are the one who carries a level of determination that is unpaired by most people in his lifetime. You love to compete with yourself equally as you contend with others. Your career would have an example of accomplishments, and you will attain some victories and milestones to be an authority in your particular industry.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Short-tempered, wild, and fiery
                </p><br/>
Aries men infrequently accept their weaknesses and are not so keen to rectify their errors. You mainly lose your character when people don't obey your ideas and come up with different views, suggestions, and opinions. The cardinal and dynamic forces of the sign make you antagonistic and sometimes unbalanced. The ruling planet Mars would create the thirst for waging war. To alleviate your warrior drive, you may constantly incite fights and arguments with other men.
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
 Enthusiastic and energetic
                </p><br/>
You are highly active, vibrant, and energetic. You adore doing things quickly and occasionally even ruthlessly. You are never exhausted and love to take on challenges. You would start assignments with great eagerness and affection, but it doesn't last for a long time. You are enthusiastic and excited till the time things are going in the way as expected, and there is something new to do in it. After some point in time, when items become repetitive and monotonous, your energy and enthusiasm would slow down. Maintaining morale is the biggest challenge for you.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
Excitement
                </p><br/>
You are very excited to do unique things and have a diverse approach to doing something. Anything predictable and/ or directly from the textbook dampens your spirits. You are always ready for adventurous competitions and travels. You love to have a jollies in your life. You are eager to play sports that are challenging in nature. Your love sports like mountain climbing, biking, car racing, and martial arts.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">
 How is Aries man in love and relationships?
                </p><br/>
                <p className="playfair text-black text-xl md:text-3xl">
 Also, know Aries in the relationship.
                </p>
 <br/>
Aries man in love and relationships is like an open book that can be easily readable. You will do anything to win the core of your lady love. You will urge her and make romantic efforts to make a girl fall in love with you. But sometimes, you get lost in love and romance and do not know what you are doing. You will not be able to articulate your feelings to her and hence feel faraway and irritated. The only problem you face in love and relationships is attaching to a partner. You are a more kind of free bird and consistently urge for freedom. But the moment you fall profoundly in love with someone, you will transform yourself thoroughly to keep her happy, comfortable, and satisfied. Find out your ideal partner from the free love horoscope report. 
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 How To Attract Aries Men
                </p><br/>
If you have your stares on an Aries man, you need to have the most acceptable plan to grab their hearts. Because Aries men are best in everything, they want the same in their partner and that they would be the best in everything and will never stop aiming; here are some habits to attract Aries men.<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be Honest:</p>A excitable sign Aries always wants to explore the world, learn new things, and be creative in many ways. They like experiences, adventures, and fun activities. So forever be up for it. Take them out on a date to a site where you can immerse yourself in adventurous activities.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be confident:</p>Aries are addicted to trust, loyalty, and confidence. Aries love to bear the lead in everyday situations and like challenges. They also let a companion take the information.
 <br/><br/>
     
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
